import React from "react";

import styles from "./styles/Dashboard.module.scss";

import { Col, Row } from "react-bootstrap";
import { SubTitle } from "../../Components/CustomComponents";
// import MatchCard from '../../Components/Cards/MatchCard';
// import TournamentCard from '../../Components/Cards/TournamentCard';
import { FaUsers } from "react-icons/fa";
import { FaUserNurse } from "react-icons/fa6";
import { GiBabyfootPlayers, GiTeamDowngrade } from "react-icons/gi";
import { RiParentFill } from "react-icons/ri";
import { TbTournament } from "react-icons/tb";
import DashboardCard from "../../Components/Cards/DashboardCard";
import { ROUTES } from "./constants";

export default function Dashboard({ setPage }) {
  const handleViewLiveMatches = () => {
    setPage(ROUTES.LIVE_MATCHES);
  };

  const handleViewTournament = () => {
    setPage(ROUTES.TOURNAMENTS);
  };

  return (
    <div className={styles.Dashboard}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle text={"Dashboard"} classes="text-black" />
        </div>
      </div>

      <Row className={`${styles.matchRow} w-100 mt-2 mb-5`}>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#934AFC"}
            bg2={"#49A4FD"}
            icon={<FaUserNurse color="#934AFC" />}
            number={252}
            text={"Numbers of Coaches"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#934AFC"}
            bg2={"#49A4FD"}
            icon={<RiParentFill color="#934AFC" />}
            number={252}
            text={"Numbers of Parents"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#934AFC"}
            bg2={"#49A4FD"}
            icon={<GiBabyfootPlayers color="#934AFC" />}
            number={252}
            text={"Numbers of Player"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#934AFC"}
            bg2={"#49A4FD"}
            icon={<TbTournament color="#934AFC" />}
            number={252}
            text={"Numbers of Tournaments"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#934AFC"}
            bg2={"#49A4FD"}
            icon={<GiTeamDowngrade color="#934AFC" />}
            number={252}
            text={"Numbers of Matches"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#934AFC"}
            bg2={"#49A4FD"}
            icon={<FaUsers color="#934AFC" />}
            number={252}
            text={"Numbers of users"}
          />
        </Col>
      </Row>
    </div>
  );
}
